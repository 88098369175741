import React, { useEffect, useState } from "react";

function Home() {
  const [result, setResult] = useState("Fetching...");

  useEffect(() => {
    fetch("weatherforecast", { mode: "cors" })
      .then((response) => response.json())
      .then((r) => {
        console.log(r);
        setResult(JSON.stringify(r));
      })
      .catch((e) => setResult(e.toString()));
  }, []);

  return (
    <>
      <h1>Home</h1>
      <div>{result}</div>
    </>
  );
}

export default Home;
