import { useEffect, useState } from "react";
import "./About.css";

// see https://cloudinary.com/blog/guest_post/embed-a-youtube-player-into-a-react-app

function About() {
  const [contentRef, setContentRef] = useState<any>();
  const [url, setUrl] = useState("");

  const setVideo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setUrl("https://www.youtube.com/embed/uejAVnWPr2k?&autoplay=1");
  };

  const clearVideo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setUrl("");
  };

  useEffect(() => {
    console.log("BAM!");
  }, [url]);

  return (
    <>
      <h1>About</h1>
      <div className="parent">
        {
          <iframe
            ref={setContentRef}
            className="player"
            src={url}
            title="YouTube video player"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        }
        <button className="theButton" onClick={setVideo}>
          Start
        </button>
        <button className="theButton2" onClick={clearVideo}>
          Stop
        </button>
      </div>
    </>
  );
}

export default About;
