import { NavLink } from "react-router-dom";
import "./NavBar.css";
import { useEffect, useState } from "react";

function NavBar() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const setOnline = () => setIsOnline(true);
  const setOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);
    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  });

  const onlineText = isOnline ? "Online" : "Offline";
  const onlineClassName = isOnline ? "online" : "offline";

  return (
    <div className="navbar">
      <h1>
        Navbar - <span className={onlineClassName}>{onlineText}</span>
      </h1>
      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
      </ul>
      <input></input>
    </div>
  );
}

export default NavBar;
